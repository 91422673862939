import React from 'react';
import {
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TablePagination,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    outsideTable: {
      boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
      backgroundColor: 'white',
      borderRadius: '4px',
      marginLeft: '25px',
    },
    tableTh: {
      padding: '6px 24px 6px 16px',
      fontSize: '0.875rem',
      lineHeight: '1.5',
    },
    tableTd: {
      padding: '6px 24px 6px 16px',
      fontSize: '0.875rem',
      lineHeight: '2.5',
    },
    header: {
        color: '#323232',
        marginLeft: '25px',
    },
    listContainer: {
        marginLeft: '25px',
    },
});

const BCDatagrid = (propsDataGrid) => {
  const classes = useStyles();

  const { children, dataGridValues } = propsDataGrid;
  const {
    dataKey,
    ids,
    total,
    rowsPerPage,
    setRowsPerPage,
    setNewPage,
    newPage,
  } = dataGridValues;
  console.log(dataGridValues);


  const handleChangePage = (event, page) => {
    setNewPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    const vlrDecimal = parseInt(event.target.value, 10);
    setRowsPerPage(vlrDecimal);

    setNewPage(0);
  };

  return (
    <>
      <div className={classes.outsideTable}>
        <Table style={{ width: '100%' }}>
          <TableHead>
            <TableRow>
              {children && children.map(({ props }) => (
                <TableCell className={classes.tableTh}>
                  {props && props.label ? props.label : props.source}
                </TableCell>
              ))
            }
            </TableRow>
          </TableHead>
          <TableBody>
            { dataGridValues.total === 0 &&
                  <TableRow>
                    <TableCell>No hay evidencias</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
            }
            {ids && ids.map(id => (
              <TableRow key={id}>
                {React.Children.map(children,
                  child => (
                    <TableCell className={classes.tableTd} key={id}>

                      {React.cloneElement(child,
                        { record: dataKey[id] },
                        (child.props.children ? child.props.children : null))}

                    </TableCell>
                  ))}
              </TableRow>
            ))
            }
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[50, 100, 250]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={newPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="Results per Page:"
        />
      </div>
    </>
  );
};


BCDatagrid.propTypes = {
  source: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};


export default BCDatagrid;
