import React, { Fragment } from 'react';
import { ReferenceInput, SelectInput, required } from 'react-admin';
import { useForm } from 'react-final-form';

const EstadosTramite = ({ formData, ...rest }) => {
    const form = useForm();

    const filtroEstados = (data) => {
        console.log(data);
        if (!data.tramite) {
            return {}
        } else {
            return {'tramites.id': data.tramite.id};
        }
    }

     return (
        <Fragment>
            <ReferenceInput label="Tramite" source="tramite.id" reference="tramites"  onChange={value => form.change('estado.id', null)} sort={{ field: 'nombre', order: 'ASC' }} validate={[required("Requerido")]} perPage={200}>
                <SelectInput optionText="nombre" />
            </ReferenceInput>
            <ReferenceInput label="Estado" source="estado.id" reference="estados" filter={filtroEstados(formData)} sort={{ field: 'estado', order: 'ASC' }} validate={[required("Requerido")]} perPage={200}  {...rest}>
                <SelectInput optionText="estado" />
            </ReferenceInput>
        </Fragment>
     )
};

export default EstadosTramite;