import React, { Fragment } from 'react';
import { Create, ReferenceInput, SelectInput, TextInput, SimpleForm, Toolbar, SaveButton, FormDataConsumer, required } from 'react-admin';
import { useForm } from 'react-final-form';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import { withStyles } from '@material-ui/core/styles';
import EstadosTramite from './componentes/EstadosTramite';
 
const styles = {
    root: {
        "& > div": {
            display: 'flex',
            flexWrap: 'wrap',
            "& > div": {
                width: '50%',
                "& > div": {
                    width: '90%',
                    "& > label": {
                       transform: 'none!important',
                       transition: 'none!important',
                       position: 'initial',
                       marginBottom: '.5rem',
                       color: '#888',
                    },
                },
            },
        },
    },
    create: {
        "& > div": {
            marginTop: '0px',
            "& > div": {
                borderRadius: '0 0 4px 4px',
                marginLeft: '25px',
            },
        },
    },
    card: {
        borderRadius: '0',
        borderBottom: '1px solid #f1f1f1',
        marginLeft: '25px',
    },
    header: {
        color: '#111',
        marginLeft: '20px',
        fontWeight: '400',
        fontSize: '18px',
    },
    buttonBack : {
        width: '25%',
        alignItems: 'normal',
        justifyContent: 'left',
        paddingLeft: '15px',
        marginBottom: '20px',
    },
    select: {
        "& > div > div > div": {
        padding: '10px 20px',
        },
    },
    select1: {
        width: '100%!important',
        "& > div": {
            width: '50%!important',
            "& > div": {
                width: '90%!important',
                "& > div": {
                    padding: '10px 20px',
                },
            },
        },
    },
    field: {
        "& > div > div": {
        padding: '10px 20px!important',
        },
    },
    buttonStyle: {
        backgroundColor: 'transparent',
        paddingBottom: '20px',
        "& > button": {
            backgroundColor: '#04a9f5',
            borderColor: '#04a9f5',
            padding: '10px 20px',
            textTransform: 'capitalize',
            marginRight: '26px',
        },
    },
};

const redirect = (basePath, id, data) => `/listatramites?filter=${data.consorcio}`;
const redirectEvidencia = (basePath, id, data) => `/evidencias/create?source=${JSON.stringify({ "consorcio_tramite.id": id })}`;


const CreateToolbar = withStyles(styles)(({ classes, ...props }) => (
    <Toolbar {...props} className={classes.buttonStyle} >
        <SaveButton
            label="Guardar"
            redirect={redirect}
            submitOnEnter={false}
        />
        <SaveButton 
            label="Cargar Evidencias"
            redirect={redirectEvidencia}
            submitOnEnter={false}
        />
    </Toolbar>
));

const AdmConsorcio = ({ formData, ...rest }) => {
    const form = useForm();

    const filtroConsorcio = (data) => {
        if (!data.administracion) {
            return {}
        } else {
            return {'cliente': data.administracion};
        }
    }

     return (
        <Fragment>
            <ReferenceInput label="Administración" source="administracion" reference="clientes" onChange={value => form.change('consorcio', null)} sort={{ field: 'nombre', order: 'ASC' }} validate={[required("Requerido")]} perPage={500}>
                <SelectInput optionText="nombre" />
            </ReferenceInput>
            <ReferenceInput label="Consorcio" source="consorcio" reference="consorcios" filter={filtroConsorcio(formData)} sort={{ field: 'nombre', order: 'ASC' }} validate={[required("Requerido")]} perPage={500}  {...rest}>
                <SelectInput optionText="nombre" />
            </ReferenceInput>
        </Fragment>
     )
};



export const NuevoTramite = withStyles(styles)(({ classes, ...props }) => {

    return (
    <>
    <Button className={classes.buttonBack} href={'/#/listatramites'}>
        <NavigateBefore />
        Lista de Trámites
    </Button>
    <Card className={classes.card}>
        <div>
            <h1 className={classes.header}>Nuevo Trámite</h1>
        </div>
    </Card>
    <Create {...props} className={classes.create}>
        <SimpleForm  toolbar={<CreateToolbar />} className={classes.root}>
            <FormDataConsumer formClassName={classes.select1}>
                {formDataProps =>
                    <> 
                    <AdmConsorcio {...formDataProps}/>
                    <EstadosTramite {...formDataProps}/>
                    </>
                }
            </FormDataConsumer>

            <TextInput label="Comentarios" multiline rows={10} source="comentario" formClassName={classes.field} />
        </SimpleForm>
    </Create>
    </>
    )
});