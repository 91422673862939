import { put, takeEvery } from 'redux-saga/effects'
import { replace } from 'connected-react-router'
import { UNREGISTER_RESOURCE } from 'react-admin'

function* logoutMonitor(action) {
  try {
    if (action.payload === 'listatramites') {
      yield put(replace({pathname: '/login', state: {nextPathname: '/'}})) // Replacing the route for the next entry!
    }
  } catch (error) {
    console.warn('logoutSaga:', error)
  }
}

function* logoutSaga() {
  yield takeEvery([UNREGISTER_RESOURCE], logoutMonitor) // UNREGISTER_RESOURCE - one of the last Redux Action at logout
}

export default logoutSaga  