import React from 'react';

const VerTramitesField = ({ record = {}, source }) => {
    return (
        <a href={'#/tramites?filter={"consorcio.id":' + record[source] + '}'}>
            Ver
        </a>
    );
}

export default VerTramitesField;