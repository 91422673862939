import React from 'react';
import { List, Datagrid, TextField} from 'react-admin';
import { Filter, SearchInput } from 'react-admin';
import VerTramitesField from '../tramites/VerTramitesField';
import CustomDateField from '../CustomDateField';

import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    header: {
        color: '#323232',
        marginLeft: '25px',
    },
    listContainer: {
        marginLeft: '25px',
    },
});


const ConsorcioFilter = (props) => { 
    //const classes = useStyles();
    return (
    <Filter {...props}>
        <SearchInput placeholder="Buscar" source="search_nombre" alwaysOn />
    </Filter>
    );
}

const getUsuarioFilter = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    //console.log(user);
    if (user === null) {
        return {};
    } 
    return {'cliente': user.cliente.id};
} 

export const ConsorcioList = props => {
    const classes = useStyles();
    return (
        <>
        <div className={classes.header}>
            <h1>Mis Consorcios</h1>
        </div>
        <List filters={<ConsorcioFilter />} filter={getUsuarioFilter()} className={classes.listContainer} exporter={false} bulkActionButtons={false} {...props}>
            <Datagrid>
                <TextField label="Nombre" source="nombre" />
                <TextField label="CUIT" source="cuit" />
                <CustomDateField source="vencimientomedpat" label="Venc M.Pat" />
                <CustomDateField source="vencimientoley" label="Venc Ley 257" />
                <VerTramitesField label="Trámites" source="id" />
            </Datagrid>
        </List>
        </>
    );
}