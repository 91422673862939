import * as React from "react";
import moment from "moment";

const CustomDateField = ({ record = {}, source }) => {
    if (record[source]) {
        return (
            <span>
                {moment(record[source]).format('DD/MM/YYYY')}
            </span>
        );
    } else {
        return (
            <span></span>
        );
    };
   
}

export default CustomDateField;