import React, {useCallback} from 'react';
import { Datagrid, TextField,  DateField, EditButton } from 'react-admin';
import { Filter, ReferenceInput, SelectInput, List } from 'react-admin';

import { TopToolbar, CreateButton } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    header: {
        color: '#323232',
        marginLeft: '25px',
    },
    h2: {
        color: '#323232',
        marginLeft: '25px',
        marginTop: '0',
        fontSize: '20px',
    },
    listContainer: {
        marginLeft: '25px',
    },
    buttonBack : {
        width: '25%',
        alignItems: 'normal',
        justifyContent: 'left',
        paddingLeft: '15px',
    },
});

const PostFilter = (props) => {

    const handleChange = useCallback((e) => {
        //console.log("e", e);
        //console.log("handleChange", props) 
        if (props.filterValues.consorcio && props.filterValues.consorcio.cliente) {
            setTimeout(() => {
                props.setFilters({
                    consorcio: { cliente: e.target.value }
                })
            })
        }
    }, [props]);

    const filtroConsorcio = () => {
        if (Object.keys(props.filterValues).length === 0 ) {
            return {}
        } else {
            //console.log("PROPSSS", props)
            return {'cliente': props.filterValues.consorcio.cliente};
        }
    }
    return ( 
    <Filter {...props}>
         <ReferenceInput label="Administración" source="consorcio.cliente" reference="clientes" inputProps={{ onChange: handleChange }} allowEmpty alwaysOn perPage={500} sort={{ field: 'nombre', order: 'ASC' }}>
            <SelectInput optionText="nombre" />
        </ReferenceInput>
        <ReferenceInput label="Consorcio" source="consorcio.id" reference="consorcios" allowEmpty alwaysOn filter={filtroConsorcio()} perPage={500} sort={{ field: 'nombre', order: 'ASC' }}>
            <SelectInput optionText="nombre" />
        </ReferenceInput>
    </Filter>
    );
};

const ListActions = ({
    hasCreate, // you can hide CreateButton if hasCreate = false
    basePath,
}) => (
    <TopToolbar>
        <CreateButton label="Nuevo" basePath={basePath} />
    </TopToolbar>
);

export const CargaTramites = (props) => {
    //console.log(props);
    const classes = useStyles();
    return (
        <>
        <h1 className={classes.header}>Trámites</h1>
        <List 
            filters={<PostFilter />} 
            exporter={false} 
            className={classes.listContainer}
            actions={<ListActions />} 
            {...props}>
            <Datagrid>
                <DateField source="created_at" label="Fecha" locales="fr-FR" options={{ day: 'numeric', month: 'numeric', year: 'numeric' }}/>
                <TextField source="tramite.nombre" label="Nombre" />
                <TextField source="estado.estado" label="Estado"/>
                <EditButton label="Editar" />
            </Datagrid>
        </List>
        </>
        );
    };