import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';

const BCTheme = createMuiTheme({
    typography: {
        fontFamily: 'Lato,sans-serif',
    },
    palette: {
        primary: blue,
    },
    overrides: {
        MuiTableRow: {
            root: {
                "&:nth-of-type(odd)": {
                    backgroundColor: '#fafafa',
                },
            },
            head: {
                "& > th ": {
                    backgroundColor: '#313131',
                    color: 'white',
                    fontWeight: 'bold',
                },
            },    
        },
        MuiTableSortLabel: {
            root: {
                "&:hover":{
                    color: 'white',
                },
            },
            active: {
                color: 'white!important',
            },
            icon: {
                color: 'white!important',
            },
        },
    },
});

export default BCTheme;