import React from 'react';
import { useQuery, Datagrid, TextField, DateField, ShowButton } from 'react-admin';
import { Filter, /* ReferenceInput, SelectInput,  */SearchInput, List } from 'react-admin';
import Button from '@material-ui/core/Button';
import NavigateBefore from '@material-ui/icons/NavigateBefore';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    header: {
        color: '#323232',
        marginLeft: '25px',
    },
    h2: {
        color: '#323232',
        marginLeft: '25px',
        marginTop: '0',
        fontSize: '20px',
    },
    listContainer: {
        marginLeft: '25px',
    },
    buttonBack : {
        width: '25%',
        alignItems: 'normal',
        justifyContent: 'left',
        paddingLeft: '15px',
    },
});

const TramitesFilter = (props) => (
    <Filter {...props}>
        <SearchInput placeholder="Buscar" source="search_tramite.nombre" alwaysOn />
    </Filter>
);

const getConsorcio = (props) => {
    let params = new URLSearchParams(props.location.search);
    console.log(params.get("filter"));
    let filterConsorcio = params.get("filter");
    let objectConsorcio = JSON.parse(filterConsorcio);
    console.log(objectConsorcio['consorcio.id']);
    let idConsorcio = objectConsorcio['consorcio.id'];

    return idConsorcio;
}

let fakeProps = {
    basePath: "/listatramites",
    hasCreate: false,
    hasEdit: false,
    hasList: true,
    hasShow: false,
    history: {},
    location: { pathname: "/", search: "", hash: "", state: undefined },
    match: { path: "/", url: "/", isExact: true, params: {} },
    options: {},
    permissions: null,
    resource: "listatramites"
}

export const TramitesList = (props) => {
    console.log(props);
    const classes = useStyles();
    const { data, loading, error } = useQuery({ 
        type: 'getOne',
        resource: 'consorcios',
        payload: { id: getConsorcio(props) }
    });
    console.log("data",data);
    if (loading && !error) { return <div>Cargando...</div>; }
    if (error || data === undefined) {
        return <div />;
    }
    return (
        <>
        <Button className={classes.buttonBack} href="/#/consorcios" >
            <NavigateBefore />
            Mis Consorcios
        </Button>
        <h1 className={classes.header}>Mis Trámites</h1>
        <h2 className={classes.h2}>Consorcio: {data.nombre}</h2>
        <List filters={<TramitesFilter />} exporter={false} bulkActionButtons={false} className={classes.listContainer} {...fakeProps}>
        <Datagrid>
            <DateField source="created_at" label="Fecha" locales="fr-FR" options={{ day: 'numeric', month: 'numeric', year: 'numeric' }}/>
                {/*<ReferenceField source="userId" reference="users">
                    <TextField source="name" />
                </ReferenceField>*/}
                <TextField source="tramite.nombre" label="Nombre" />
                <TextField source="estado.estado" label="Estado"/>
                <ShowButton label="Detalle" />
            </Datagrid>
        </List>
        </>
    );
};