import React from 'react';
import { AppBar } from 'react-admin';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    menuButton: {
        display: 'none',
    },
    header: {
        color: '#0071b3',
        backgroundColor: '#fff',
        paddingLeft: '30px',
    },
    logoContainer: {
        flex: '1',
    },
    logo: {
        height: '60px',
    },
    infoContainer: {
        margin: '30px 0 0 0',
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0px 4px 5px 0px rgba(0,0,0,0.14)',
        color: '#323232',
    },
    info: {
        width: '33%',
        padding: '0 20px 10px 30px',
        alignSelf: 'flex-end',
    },
    boldText: {
        fontWeight: 'bold',
        margin: '5px 0 5px 0',
    },
    marginText: {
        margin: '5px 0 5px 0',
    }
});

const HeaderUser = props => {
    const classes = useStyles();
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) return (<div />);
    const day = moment(user.matriculavence);
    const matriculaday = day.format('DD/MM/YYYY');
    return (
        <>
        <AppBar className={classes.header} classes={classes}  {...props}>
            <div className={classes.logoContainer}><img className={classes.logo} src={require('./bigcons.jpeg')} alt="Logo" /></div>
        </AppBar>
        <div className={classes.infoContainer}>
            <div className={classes.info}>
                <p className={classes.boldText}>Administración: {user.cliente.nombre}</p>
                <p className={classes.boldText}>Administrador: {user.nombre}</p>
                
            </div>
            <div className={classes.info}>
                <p className={classes.boldText}>CUIT: {user.username}</p>
            </div>
            <div className={classes.info}>
                <p className={classes.boldText}>RPA: {user.RPA}</p>
                <p className={classes.marginText}>Vence: {matriculaday}</p>
            </div>
        </div>
        </>
    );
}

export default HeaderUser;