import React from 'react';
import { Layout } from 'react-admin';
import HeaderUser from '../header/Header.js';
import BCSideBar from './BCSideBar';

const BCLayout = props => <Layout
    {...props}
    appBar={HeaderUser}
    sidebar={BCSideBar}
/>;

export default BCLayout;