import * as React from "react";
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import { SimpleForm, TextInput, ReferenceInput, SelectInput, Create, Toolbar, SaveButton, FileInput, FileField } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  inlineBlock: { display: 'inline-block', marginRight: '50px'},
  root: {
    "& > div": {
        display: 'flex',
        flexWrap: 'wrap',
        "& > div": {
            width: '80%',
            "& > div": {
                width: '90%',
                "& > label": {
                   transform: 'none!important',
                   transition: 'none!important',
                   position: 'initial',
                   marginBottom: '.5rem',
                   color: '#888',
                },
            },
        },
    },
  },
  create: {
    "& > div": {
        marginTop: '0px',
        "& > div": {
            borderRadius: '0 0 4px 4px',
            marginLeft: '25px',
        },
    },
  },
  card: {
      borderRadius: '0',
      borderBottom: '1px solid #f1f1f1',
      marginLeft: '25px',
  },
  header: {
    color: '#111',
    marginLeft: '20px',
    fontWeight: '400',
    fontSize: '18px',
  },
  buttonBack : {
      width: '25%',
      alignItems: 'normal',
      justifyContent: 'left',
      paddingLeft: '15px',
      marginBottom: '20px',
  },
  select: {
      "& > div > div > div": {
      padding: '10px 20px',
      },
  },
  selectConsorcio: {
    "& > div > div > input": {
      padding: '10px 20px',
    },
  },
  field: {
      "& > div > div": {
      padding: '10px 20px!important',
      },
  },
  buttonStyle: {
      backgroundColor: 'transparent',
      paddingBottom: '20px',
      "& > button": {
          backgroundColor: '#04a9f5',
          borderColor: '#04a9f5',
          padding: '10px 20px',
          textTransform: 'capitalize',
      },
  },
  evidencia: {
    '& > div > div': {
      paddingTop: '0px',
      '& > table': {
        width: '100%!important',
      },
    },  
  },
};


const redirect = (basePath, id, data) => `/listatramites?filter=${data.consorcio}`;

const CreateToolbar = withStyles(styles)(({ classes, ...props }) => (
    <Toolbar {...props} className={classes.buttonStyle} >
        <SaveButton
            label="Guardar"
            redirect={redirect}
            submitOnEnter={false}
        />
    </Toolbar>
));


export const NuevaEvidencia = withStyles(styles)(
  ({ classes, ...props }) => {

 return(
      <>
      <Button className={classes.buttonBack} href={'/#/listatramites'}>
          <NavigateBefore />
          Lista de Trámites
      </Button>
      <Card className={classes.card}>
          <div>
              <h1 className={classes.header}>Nueva Evidencia</h1>
          </div>
      </Card>
      <Create {...props} className={classes.create} actions={false}>
          <SimpleForm  toolbar={<CreateToolbar />} className={classes.root}>
            {/*  <TextInput disabled label="ID tramite" source="id" /> */}
              <ReferenceInput disabled label="Trámite" source="consorcio_tramite.id" reference="listatramites" formClassName={classes.select}>
                <SelectInput optionText="tramite.nombre" />
              </ReferenceInput>
              <TextInput label="Descripción" source="descripcion" reference="evidencias" multiline rows={3} />
              <FileInput source="adjunto" label="Cargar evidencia" accept="application/pdf" placeholder={<p>Arrastre sus archivos aquí</p>}>
                  <FileField source="url" title="name" />
              </FileInput>

          </SimpleForm>
      </Create>
      </>
  );
});