import React from 'react';

const DescargaTramiteUrl = ({ record = {}, source }) => {
    return (
        <a href={'https://admin.bigconsultingadmin.com.ar' + record[source][0].url } target="_blank" rel="noopener noreferrer">
            Descargar
        </a>
    );
}

export default DescargaTramiteUrl;