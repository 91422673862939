import * as React from "react";
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import { SimpleForm, TextInput, ReferenceInput, SelectInput, Edit, Toolbar, SaveButton, ReferenceManyField, Datagrid, DateField, TextField, EditButton, FormDataConsumer } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import DescargaTramiteUrl from './DescargaTramiteUrl.js';
import { Pagination } from 'react-admin';
import EstadosTramite from './componentes/EstadosTramite';

const styles = {
  inlineBlock: { display: 'inline-block', marginRight: '50px'},
  root: {
    "& > div": {
        display: 'flex',
        flexWrap: 'wrap',
        "& > div": {
            width: '50%',
            "& > div": {
                width: '90%',
                "& > label": {
                   transform: 'none!important',
                   transition: 'none!important',
                   position: 'initial',
                   marginBottom: '.5rem',
                   color: '#888',
                },
            },
        },
    },
  },
  create: {
    "& > div": {
        marginTop: '0px',
        "& > div": {
            borderRadius: '0 0 4px 4px',
            marginLeft: '25px',
        },
    },
  },
  card: {
      borderRadius: '0',
      borderBottom: '1px solid #f1f1f1',
      marginLeft: '25px',
  },
  header: {
    color: '#111',
    marginLeft: '20px',
    fontWeight: '400',
    fontSize: '18px',
  },
  buttonBack : {
      width: '25%',
      alignItems: 'normal',
      justifyContent: 'left',
      paddingLeft: '15px',
      marginBottom: '20px',
  },
  select: {
      "& > div > div > div": {
      padding: '10px 20px',
      },
  },
  select1: {
      width: '100%!important',
      "& > div": {
          width: '50%!important',
          "& > div": {
              width: '90%!important',
              "& > div": {
                  padding: '10px 20px',
              },
          },
      },
  },
  selectConsorcio: {
    "& > div > div > input": {
      padding: '10px 20px',
    },
  },
  field: {
      "& > div > div": {
      padding: '10px 20px!important',
      },
  },
  buttonStyle: {
      backgroundColor: 'transparent',
      paddingBottom: '20px',
      "& > button": {
          backgroundColor: '#04a9f5',
          borderColor: '#04a9f5',
          padding: '10px 20px',
          textTransform: 'capitalize',
          marginRight: '26px',
      },
  },
  evidencia: {
    '& > div > div': {
      paddingTop: '0px',
      '& > table': {
        width: '100%!important',
      },
    },  
  },
};

const redirect = (basePath, id, data) => `/listatramites?filter=${data.consorcio}`;
const redirectEvidencia = (basePath, id, data) => `/evidencias/create?source=${JSON.stringify({ "consorcio_tramite.id": id })}`;

const CreateToolbar = withStyles(styles)(({ classes, ...props }) => (
    <Toolbar {...props} className={classes.buttonStyle} >
        <SaveButton
            label="Guardar"
            redirect={redirect}
            submitOnEnter={false}
        />
        <SaveButton 
            redirect={redirectEvidencia}
            label="Cargar Evidencias"
            submitOnEnter={false}
        />
    </Toolbar>
));


export const EditarTramite = withStyles(styles)(({ classes, ...props }) => (
    <>
    <Button className={classes.buttonBack} href={'/#/listatramites'}>
        <NavigateBefore />
        Lista de Tramites
    </Button>
    <Card className={classes.card}>
        <div>
            <h1 className={classes.header}>Editar Tramite</h1>
        </div>
    </Card>
    <Edit {...props} className={classes.create} actions={false}>
         <SimpleForm  toolbar={<CreateToolbar />}  className={classes.root}>
            <ReferenceInput disabled label="Administración" placeholder="Administración" source="consorcio.cliente" reference="clientes" formClassName={classes.select}>
              <SelectInput optionText="nombre" />
            </ReferenceInput>
            <TextInput disabled label="Consorcio" source="consorcio.nombre" formClassName={classes.selectConsorcio} />
            <FormDataConsumer formClassName={classes.select1}>
                {formDataProps => 
                    <EstadosTramite {...formDataProps}/>
                }
            </FormDataConsumer>

            <TextInput  label="Comentarios" multiline rows={10} source="comentario" formClassName={classes.field} />

            <ReferenceManyField
              reference="evidencias"
              target="consorcio_tramite.id"
              label="Evidencias"
              sort={{ field: "created_at", order: "DESC" }}
              formClassName={classes.evidencia}
              pagination={<Pagination />}
              perPage={5}
              emptyText={"No hay evidencias"}
            >
              <Datagrid>
                <DateField label="Fecha" source="created_at" locales="fr-FR" options={{ day: 'numeric', month: 'numeric', year: 'numeric' }} />
                <TextField label="Descripcion" source="descripcion" />
               { <DescargaTramiteUrl label="Descargar" source="adjunto" title="Descargar" />}
                <EditButton label="Editar" />
              </Datagrid>
            </ReferenceManyField>
        </SimpleForm>
    </Edit>
    </>
));

