import React from 'react';
import simpleRestProvider from './dataProvider';

import { Admin, Resource, fetchUtils } from 'react-admin';
import { ConsorcioList } from './consorcios/Consorcios';
import { CargaTramites } from './tramites/CargaTramites';
import DetalleTramite from './tramites/DetalleTramite';
import ApartmentIcon from '@material-ui/icons/Apartment';
import DescriptionIcon from '@material-ui/icons/Description';
import { NuevoTramite } from './tramites/NuevoTramite';
import { EditarTramite } from './tramites/EditarTramite';
import { TramitesList } from './tramites/Tramites';
import { EditarEvidencia } from './tramites/EditarEvidencia';
import { NuevaEvidencia } from './tramites/NuevaEvidencia';

import authProvider from './authProvider';
import BCLayout from './BCSpecial/BCLayout'
import Login from './login/Login'
import BCTheme from './BCSpecial/BCTheme'
import customRoutes from './customRoutes';
import Cookies from './helpers/Cookies';
import logoutSaga from './helpers/logoutSaga';

const httpClient = (url, options = {}) => {
  if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = Cookies.getCookie('token')
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
}

const uploadFields = ["images", "files", "avatar", "adjunto"];
const dataProvider = simpleRestProvider('https://admin.bigconsultingadmin.com.ar', httpClient, uploadFields);

const App = () => (
  <Admin 
  
  customRoutes={customRoutes} 
  customSagas={[ logoutSaga ]}
  layout={BCLayout} 
  authProvider={authProvider} 
  dataProvider={dataProvider} 
  loginPage={Login} 
  theme={BCTheme}
  >

  {permissions => {
    if (permissions === 'Master') {
      return [
        <Resource options={{ label: 'CargaTramites' }} name="listatramites" list={CargaTramites} edit={EditarTramite} show={DetalleTramite} create={NuevoTramite} icon={DescriptionIcon} />,
        <Resource options={{ label: 'Consorcios' }} name="consorcios" list={ConsorcioList} icon={ApartmentIcon} />,
        <Resource name="clientes" />,
        <Resource name="tramites" />,
        <Resource name="estados" />, 
        <Resource name="evidencias" edit={EditarEvidencia} create={NuevaEvidencia} />,
        <Resource name="listadetramites" />
      ]
    } else {
        return [
          <Resource options={{ label: 'Consorcios' }} name="consorcios" list={ConsorcioList} icon={ApartmentIcon} />,
          <Resource options={{ label: 'Trámites' }} name="listatramites" list={TramitesList} show={DetalleTramite} icon={DescriptionIcon} />,
          <Resource name="clientes" />,
          <Resource name="tramites" />,
          <Resource name="estados" />,
          <Resource name="evidencias" />
        ]
      }
    } 
  }
  </Admin>
);

export default App;