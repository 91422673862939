import React, { useState } from 'react';
import { useQuery, SimpleShowLayout, TextField, DateField, RichTextField } from 'react-admin';
import BCDatagrid from '../BCSpecial/BCDatagrid.js';
import { connect } from 'react-redux';
import DescargaTramiteUrl from './DescargaTramiteUrl.js';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import NavigateBefore from '@material-ui/icons/NavigateBefore';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    header: {
        color: '#323232',
        marginLeft: '25px',
    },
    listContainer: {
        marginLeft: '25px',
        marginRight: '25px',
        padding: '0',
    },
    cardStyle: {
        marginLeft: '25px',
        marginBottom: '16px',
    },
    cardField: {
        '& > div': { 
            flexDirection: 'row',
            width: '100%',
            '& label': {
                transform: 'none',
                width: '15%',
                lineHeight: 'normal',
                '& span': {
                    color: '#323232',
                    fontSize: '20px',
                    lineHeight: 'normal',
                },
            },
            '& > div': {
                padding: '0',
            },
        },
        '& span': {
            color: '#323232',
            fontSize: '20px',
            lineHeight: 'normal',
        },
    },
    cardFieldEstado: {
        '& > div': { 
            flexDirection: 'row',
            width: '100%',
            '& label': {
                transform: 'none',
                width: '15%',
                lineHeight: 'normal',
                '& span': {
                    color: '#323232',
                    fontSize: '20px',
                    lineHeight: 'normal',
                },
            },
            '& > div': {
                padding: '0',
            },
        },
        '& span': {
            color: '#0074ae',
            fontSize: '20px',
            lineHeight: 'normal',
        },
    },
    cardStyleComments: {
        marginLeft: '25px',
        marginBottom: '32px',
        backgroundColor: '#f0f0f0',
    },
    listContainerComments: {
        marginLeft: '25px',
        marginRight: '25px',
        marginBottom: '8px',
        padding: '0',
    },
    commentsField: {
        '& > div': { 
            marginTop: '8px',
            width: '100%',
            '& label': {
                transform: 'none',
                lineHeight: 'normal',
                '& span': {
                    color: '#323232',
                    fontSize: '20px',
                    lineHeight: 'normal',
                },
            },
            '& > div': {
                width: '90%',
                padding: '0',
                marginTop: '15px',
            },
        },
        '& span': {
            color: '#323232',
            fontSize: '16px',
            lineHeight: 'normal',
        },
    },
    buttonBack : {
        width: '25%',
        alignItems: 'normal',
        justifyContent: 'left',
        paddingLeft: '15px',
    },
});


const convertKeyToIndice = (datas) => {
    const data = datas;
    let ids = [];
    const dataKey = [];

    if (data) {
        ids = data.map((elemento) => {
        dataKey[elemento.id] = elemento;
        return elemento.id;
        });
        return ({ ids, dataKey });
    }
    return ({});
};

const DetalleTramite = (props) => {
    const classes = useStyles();

    const { id: idTramite } = props;

    const [newPage, setNewPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    const buildDataGridValues = (evidencias) => {
        return {
           ...convertKeyToIndice(evidencias),
           total: evidencias.length,
           rowsPerPage,
           setRowsPerPage,
           newPage,
           setNewPage,
       };   
   }

    //console.log("Tramite:", idTramite);

    const getUrlBack = (data) => {
        return '/#/listatramites?filter={"consorcio.id":' + data.consorcio.id + '}';
    };

    const { data, loading, error } = useQuery({ 
        type: 'getOne',
        resource: 'listatramites',
        payload: { id: idTramite }
    });
    let tramite = data
     //console.log("Loading", loading)
     //console.log("Error", error)
     //console.log("Data", data)
    //setTramite(data);

    if (loading && !error) { return <div>Wait...</div>; }
    if (error || tramite === undefined) {
        //props.showNotification('Not Found');
        return <div />;
    }

    return (
        <>
        <Button className={classes.buttonBack} href={getUrlBack(data)}>
            <NavigateBefore />
            Mis Tramites
        </Button>
        <h1 className={classes.header}>Detalle de Trámite</h1>
        <Card className={classes.cardStyle}>
            <SimpleShowLayout record={tramite} className={classes.listContainer}>
                <TextField label="Consorcio:" source="consorcio.nombre" className={classes.cardField}/>
                <TextField label="Trámite:" source="tramite.nombre"  className={classes.cardField}/>
                <DateField label="Fecha de inicio:" source="tramite.created_at" className={classes.cardField} locales="fr-FR" options={{ day: 'numeric', month: 'numeric', year: 'numeric' }}/>
                <TextField label="Estado:" source="estado.estado"  className={classes.cardFieldEstado}/>
                <TextField label="Descripción:" source="estado.descripcion"  className={classes.cardField}/>
               
            </SimpleShowLayout>
        </Card>
        <Card className={classes.cardStyleComments}>
            <SimpleShowLayout record={tramite} className={classes.listContainerComments}>
                <RichTextField label="Comentarios y observaciones" source="comentario" className={classes.commentsField} emptyText={"No hay comentarios"} />
            </SimpleShowLayout>
        </Card>
        <BCDatagrid dataGridValues={buildDataGridValues(tramite.evidencias)} >
            <DateField label="Fecha" source="created_at" locales="fr-FR" options={{ day: 'numeric', month: 'numeric', year: 'numeric' }} />
            <TextField label="Descripcion" source="descripcion" />
            <DescargaTramiteUrl label="Descargar" source="adjunto" title="Descargar" />
        </BCDatagrid>
        </>
    );
};

export default connect()(DetalleTramite);
