import React from 'react';
import { Sidebar } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    sidebar: {
        display: 'none',
    }
});

const BCSideBar = props => {
    const classes = useStyles();
    return (
        <Sidebar  className={classes.sidebar} {...props}>

        </Sidebar>
    );
}

export default BCSideBar;